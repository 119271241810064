<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="sidebarx"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 sidebar-topfixpart bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
      <vs-button class="h2-class pd-10 cust-remove-boxsh">UTILITY</vs-button>
    </div>

    <div class="Main-inviteallsection mt-16">
      <div class="input-invite-sec pd-10">
        <vs-input class="inputx w-full" placeholder="Name" v-model="name" />
      </div>
      <vs-button
        :disabled="!name"
        color="primary"
        type="filled"
        class="w-full block absolute bottom-0 rounded-none h2-class"
        @click="handleClick"
      >
        <span class="h3-upp-class h2-class">
          ADD
        </span>
      </vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import { currentCommunityName } from "@/Core/config/communityDetails.js";

export default {
  components: {},
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) {
        this.name = "";
      }
    },
    $route() {
      this.isSidebarActiveLocal = false;
      this.$emit("closeSidebar");
    }
  },
  data() {
    return {
      name: ""
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    }
  },
  methods: {
    handleClick() {
      //   if (this.isInsert) {
      this.handleInsert();
      //   } else {
      //     this.handleUpdate();
      //   }
    },
    async handleUpdate() {
      //   this.$vs.loading();
      //   const payload = {
      //     id: this.data.id,
      //     newIPAddress: this.ipAddress,
      //     author: this.$store.state.user.username
      //   };
      //   const data = await this.$store.dispatch("whitelist/updateIP", payload);
      //   if (data) {
      //     this.$vs.loading.close();
      //     this.$emit("closeSidebar");
      //     await this.alert("success", "IP Updated Successfully");
      //   }
    },
    async handleInsert() {
      if (!this.name) return;
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        name: this.name,
        button: "Open"
      };
      try {
        this.$vs.loading();

        const data = await this.$store.dispatch(
          "utilities/addUtility",
          payload
        );
        if (data) {
          this.$vs.loading.close();
          this.$emit("closeSidebar");
          await this.alert("success", "Utility Added Successfully");
        }
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },

    alert(type, text) {
      return this.$swal({
        icon: type,
        title: text,
        showConfirmButton: false,
        timer: 1000
      });
    }
  }
};
</script>
