<template>
  <div
    class="vx-row lg:w-1/4 sm:w-1/4 card-bg utility-md-rg utility-card-height"
  >
    <div class="w-full">
      <div class="w-full card-titleName"></div>
      <DivLoader :id="loaderID" :isLoading="isLoading">
        <template slot="content">
          <div class="vx-main notif-top-md groups-card-h">
            <vx-card class="bShadow-remove utility-top-pd">
              <div
                class="notifi-head-scroll utility_top-50"
                v-if="utilities.length > 0 && !isLoading"
              >
                <ul v-if="check('admin')" class="utility-ul-left">
                  <li
                    class="cursor-pointer flex items-center"
                    v-for="utility of utilities"
                    :key="utility.utility_UniqueId"
                    @click="handleClick(utility)"
                  >
                    <div class="media-left">
                      <img
                        v-if="utility.utility_image != null"
                        :src="magittUrl + utility.utility_image"
                        @error="
                          utility.utility_image =
                            'uploads/defaultImages/default_post.png'
                        "
                        class="imgRadius-md"
                        alt="content-img"
                      />
                      <vs-avatar
                        v-if="utility.utility_image == null"
                        :src="null"
                        :text="utility.utility_title"
                        alt="content-img"
                        class="imgRadius-md bg-primary"
                      />
                      <!-- <img
                    v-if="utility.utility_image == null"
                    :src="magittUrl + 'uploads/defaultImages/default_group.png'"
                    class="imgRadius-md"
                    alt="content-img"
                  /> -->
                    </div>
                    <div class="padding-utility">
                      <h5 class="h4-class fontWeight-600">
                        {{ utility.utility_title }}
                      </h5>
                      <p class="text-mute-cls h4-class">
                        {{ utility.utility_description }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="utilities.length === 0 && !isLoading">
                <div class="membs-noData h4-class">
                  <p class="utility_nodata_child">No Utilities</p>
                </div>
              </div>
            </vx-card>
          </div>
        </template>
      </DivLoader>
    </div>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const utilitiesRepository = RepositoryFactory.get("utility");
const profileRepository = RepositoryFactory.get("profile");
import DivLoader from "@/Core/Views/CommonPlugins/div-loader/DivLoader";

export default {
  components: {
    DivLoader,
  },
  data() {
    return {
      token: {},
      utilities: [],
      isLoading: true,
      loaderID: "home-utilities",
      initialRole: {},
    };
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  async created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
    let token = jwt.decode(localStorage.getItem("token"));
    let payload = {
      userData: {
        id: token.userID,
      },
      communityData: {
        communityName: currentCommunityName.communityName,
      },
    };
    const { data } = await profileRepository.getAllUserData(payload);
    if (data[0].communityManager_Is_owner == 1) {
      this.initialRole = "superadmin";
    } else if (data[0].communityManager_UserId != null) {
      this.initialRole = "admin";
    } else if (data[0].usersDetails_Password == null) {
      this.initialRole = "normal";
    } else {
      this.initialRole = "editor";
    }
  },
  mounted() {
    this.getAllUtilities();
  },
  methods: {
    async getAllUtilities() {
      this.isLoading = true;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await utilitiesRepository.getAllUtilities(obj);

      this.isLoading = false;
      this.utilities = data;
    },
    handleClick(params) {
      if (params.utility_title == "MIS") {
        this.openMISPage();
      }
      if (params.utility_title == "Whitelist IP") {
        this.openWhitelistPage();
      }
      if (params.utility_title == "Economic Calendar") {
        this.$router.push({ name: "economic-calendar" }).catch(() => {});
      }
      if (params.utility_title == "Money Market Calendar") {
        this.$router.push({ name: "money-market-calendar" }).catch(() => {});
      }
      if (params.utility_title == "FX Calendar") {
        this.$router.push({ name: "fx-calendar" }).catch(() => {});
      }
    },
    openMISPage() {
      this.$router.push("/pages/MIS").catch(() => {});
    },
    openWhitelistPage() {
      this.$router.push("/pages/WhitelistIP").catch(() => {});
    },
    check(role) {
      if (role === "admin") {
        if (this.initialRole == "admin" || this.initialRole == "superadmin") {
          return true;
        }
      } else if (role === "superadmin") {
        if (this.initialRole == "superadmin") {
          return true;
        }
      }
    },
  },
};
</script>
