<template>
  <div class="flex">
    <UtilitySidebar
      :isSidebarActive="isSidebarActive"
      @closeSidebar="toggleSidebar"
    />
    <vx-card title="Utilities" class="md:w-3/5 mx-auto">
      <template slot="actions">
        <vs-button color="primary" type="filled" @click="handleAdd"
          >ADD</vs-button
        >
      </template>
      <div class="mt-12 bg-white p-5 rounded-md">
        <HomeUtilitiesSection />
      </div>
    </vx-card>
  </div>
</template>

<script>
import HomeUtilitiesSection from "@/Core/Views/pages/home/components/HomeUtilitiesSection";
import UtilitySidebar from "../components/UtilitySidebar";

export default {
  components: { UtilitySidebar, HomeUtilitiesSection },
  data() {
    return {
      isSidebarActive: false
    };
  },
  methods: {
    handleAdd() {
      this.toggleSidebar(true);
    },
    toggleSidebar(val = false) {
      this.isSidebarActive = val;
    }
  }
};
</script>
